import React, { useState, useMemo, useEffect } from 'react';
import $ from 'jquery';
import Clock from '../../clock/clock';
import ScoreToolBlock from './toolblock/toolblock';
import TextMessage from './text-message/text-message.js';
import ScoringItem from '../scoring-item/scoring-item.js';
import DBHandler from '../../../javascript/index-db/db-handler.js';
import ServingIndicator from './serving-indicator/serving-indicator.js';
import { ConfirmModel, Timestamp } from '../../../javascript/utils/utils.js';
import { endGame, speech, build_sport_function } from '../../../javascript/sport/sport-function.js';
import ConnectionIndicator from '../../connection-indicator/connection-indicator.js';
import { SPORT_BASE, NAV_CONTROL } from '../../../javascript/services/globalVars.js';
import style from './scoring-block-style.module.scss';

function ScoringBlock(Props) {
   const [gameTimer, setGameTimer] = useState(() => { });
   const [gameTimer_2, setGameTimer_2] = useState(() => { });
   const [hideGameTimer, setHideGameTimer] = useState(() => false);
   const [hideGameTimer_2, setHideGameTimer_2] = useState(() => true);
   const [debounceTime, setDebounceTime] = useState(() => Timestamp());
   const [scoreLeft, setScoreLeft] = useState(() => { });
   const [scoreRight, setScoreRight] = useState(() => { });
   const [colorTable, setColorTable] = useState(() => { });
   const [servingState, setServingState] = useState(() => { });
   const [scoreUpdate, setScoreUpdate] = useState(() => 0);
   const [btnList, setBtnList] = useState(() => []);

   let styleHeight = '';
   if (Props.cssHeight === '70') {
      styleHeight = style.hight70;
   }

   const confirm_game_won = () => {
      const scoring = SPORT_BASE.sport_object.scoring;
      if (SPORT_BASE.sport_object.game_state === 'running' && gameTimer !== false) {
         const wordType = scoring.scoring.match_won ? 'Match' : 'Game';
         const game_state = scoring.scoring.match_won ? 'match_complete' : 'game_complete';
         if (scoring.scoring.won && $('.swal2-container').length === 0) {
            ConfirmModel({
               title: `${wordType} completed?`,
               text: `Are you sure?`,
               confirmButtonText: 'Yes',
               cancelButtonText: 'Go Back!',
               allowOutsideClick: false,
            }).then((result) => {
               if (result.isConfirmed) {
                  speech(wordType);
                  const dbHandler = DBHandler();
                  dbHandler.update_app_state({ update: Timestamp(), game_state: game_state, });
                  endGame();
                  setGameTimer(false);
               } else {
                  // } else if (result.dismiss === 'cancel') {
                  SPORT_BASE.sport_object.scoring.scoring.undo();
                  if (typeof Props.callback === 'function') {
                     updateScore();
                     Props.callback();
                  }
               }
            });
         }
      }
   }

   const trigger_score = async (args = {}) => {
      try {
         const timePassed = Timestamp() - debounceTime;
         if (timePassed < 350) {
            return false;
         }
         const scoring = SPORT_BASE.sport_object.scoring;
         if (!scoring.scoring.won) {
            const side = args.pos === 'left' ? 1 : 2;
            // const result = scoring.scoring.score({ side });
            const score_fun = build_sport_function({ type: 'score', value: side });
            const result = await score_fun();
            // console.log('Result: ', result);

            setScoreUpdate(v => v + 1);
            if (typeof Props.callback === 'function') { Props.callback(); }
         } else {
            // confirm_game_won();
         }
   
         // setScoreUpdate(v => v + 1);
         setDebounceTime(Timestamp());
      } catch (error) {
         console.error('ERROR - Score-Block - trigger_score - root: ', error);
         setScoreUpdate(v => v + 1);
         setDebounceTime(Timestamp());
      }
   }


   /// TODO -- DEBUG +----------------------------------------
   var tmp_buff = { a:0, b:0};
   /// TODO -- DEBUG +----------------------------------------
   
   const updateScore = () => {
      try {
         // console.log('>>> updateScore TRIGGER <<<');
         const scoring = SPORT_BASE.sport_object.scoring;
   
         /// TODO -- DEBUG +----------------------------------------
         let val = {
            a: scoring.scoring.team_1_score.value,
            b: scoring.scoring.team_2_score.value,
         };
         if (val.a < tmp_buff.a || val.b < tmp_buff.b) {
            console.error('\n\nupdate_match - Posable Race condition detected: C3 \n\n');
         }
         tmp_buff = JSON.parse(JSON.stringify(val)) ;
         // console.log('Score: '+val.a+'-'+val.b);
         /// TODO -- DEBUG +----------------------------------------
   
         // console.log('Score A: ', scoring.scoring.team_1_score.value);
         // console.log('Score B: ', scoring.scoring.team_2_score.value);
   
         setScoreLeft({ score: scoring.scoring.team_1_score.value });
         setScoreRight({ score: scoring.scoring.team_2_score.value });





      } catch (error) {
         console.error('ERROR - Score-Block - updateScore - root: ', error);
      }
   }

   const updateState = async (args) => {
      if (typeof args.scoring.state !== 'undefined') {
         const game_clock = 'game_clock';
         const continuous_clock = 'continuous_clock';

         if (SPORT_BASE.check_continuous_clock()) {
            const test = SPORT_BASE.getClock(continuous_clock);
            setGameTimer_2(test);
            setHideGameTimer(true);
            setHideGameTimer_2(false);
         } else {
            setGameTimer_2(false);
            setHideGameTimer(false);
            setHideGameTimer_2(true);
         }

         const clockIndex = SPORT_BASE.sport_object.clocks.findIndex(clock => clock.id === game_clock);
         if (clockIndex !== -1) {
            const game_timer = SPORT_BASE.sport_object.clocks[clockIndex];
            setGameTimer(game_timer);
         }
         // console.error('>>>--- Update Scoring Sate ---<<<');  
         setServingState(args.scoring.state.serving);
         setColorTable(args.scoring.state.match.players.color_table);
         const tools = await args.get_tool_block('scoring');
         setBtnList(tools);
         updateScore();
         confirm_game_won();
      } else {
         setGameTimer(false);
         setGameTimer_2(false);
      }
   }

   useMemo(() => {
      return updateState(SPORT_BASE.sport_object);
   }, [SPORT_BASE.sport_object.scoring.state.match, scoreUpdate])


   useEffect(() => {
      NAV_CONTROL.appendCallback('scoreBlock', () => { setScoreUpdate(v => v + 1); });
      return () => {
         // >>> Runs on unLoad (Unload runs first if component is already mounted)
      };
   }, []); // >> [] -- Monitor

   
   return (
      <div className={`${style.scoreWrap} ${styleHeight}`}>
         <div className={style.scoreHead}>
            <Clock settings={gameTimer} hidden={hideGameTimer}  />
            <Clock settings={gameTimer_2} hidden={hideGameTimer_2} />
            <ConnectionIndicator/>
         </div>
         <div className={style.scoreBody}>
            <ScoringItem callback={trigger_score} settings={scoreLeft} pos="left" />
            <div className={style.scoreToolBar}>
               <ScoreToolBlock btnList={btnList} />
            </div>
            <ScoringItem callback={trigger_score} settings={scoreRight} pos="right" />
         </div>
         <div className={style.scoreFoot}>
            <ServingIndicator pos={1} servingState={servingState} update={scoreUpdate} colorTable={colorTable} />
            <TextMessage/>
            <ServingIndicator pos={2} servingState={servingState} update={scoreUpdate} colorTable={colorTable} />
         </div>
      </div>
   );
}

export default ScoringBlock;