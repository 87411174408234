import React, { useState, useEffect, useRef } from 'react';
import { useIsVisible } from 'react-is-visible';
import CastButton from '../../chrome-cast/cast-button';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { genKeyRan } from '../../../javascript/utils/utils';
import SportLoader from '../../../javascript/sport/sport-loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SPORT_BASE } from '../../../javascript/services/globalVars';
import { Handle_Game_Change } from '../../../javascript/utils/game-utils';
import { load_SportLoader_from_db } from '../../../javascript/services/globalVars';
import BtnSoundCheck from '../../static-btns/btn-sound-check/btn-sound-check';
import style from './score-setup-form-style.module.scss';


function ScoreSetupForm(Props) {
   const type = Props.type;
   const formClass = type === 'mod' ? style.modWrap : '';
   const btnText = type === 'mod' ? 'Modify Match' : 'Start Match';
   const wrapper = useRef();
   const [scoreSettings, setScoreSettings] = useState('');
   const [updateKey, setUpdateKey] = useState(0);
   const isVisible = useIsVisible(wrapper);

   const form_values = (elements) => {
      let valueObj = {};
      const sportSettings = SPORT_BASE.sport_object.settings;
      SPORT_BASE.sport_object.map(sportSettings, (item) => {
         const elementName = item.value.name;
         if (typeof elements[elementName] !== 'undefined') {
            const val = elements[elementName].value;
            valueObj[elementName] = val;
            // >>> For Sub Values
            if (typeof item.value.sub_value !== 'undefined') {
               const subName = item.value.sub_value.name;
               const subValue = elements[subName].value;
               valueObj[subName] = subValue;
            }
         }
      });
      return valueObj;
   }

   const handleSubmit = async (event) => {
      event.preventDefault();
      const matchSettings = form_values(event.target.elements);
      // console.log('matchSettings: ', matchSettings);
      Handle_Game_Change(matchSettings, type);
   }

   const handleChange = (event) => {
      const result = form_values(event.target.form.elements);
      update_scoreSettings(result);
   }

   const update_scoreSettings = async (fromValues = null) => {
      // console.warn('>>> update_scoreSettings <<< - type '+type+' :', fromValues);
      let setupElement;
      if (type === 'mod') {
         setupElement = SPORT_BASE.setupBuild(fromValues);
      } else if (type === 'new') {
         const NEW_SPORT_BASE = await load_SportLoader_from_db(true);
         // console.log('fromValues: ', fromValues);
         setupElement = NEW_SPORT_BASE.setupBuild(fromValues);
      }


      setScoreSettings(setupElement);
   }

   useEffect(() => {
      if (isVisible === true) {
         let settings = null;
         if (type === 'mod') {
            let tmpSettings = {};
            for (let a = 0; a < SPORT_BASE.sport_object.scoring.settings.length; a++) {
               const name = SPORT_BASE.sport_object.scoring.settings[a].name;
               const value = SPORT_BASE.sport_object.scoring.settings[a].value;
               tmpSettings[name] = value;
            }
            settings = tmpSettings;
         }

         update_scoreSettings(settings);
         setUpdateKey(genKeyRan('setupWrap'));
      }
   }, [isVisible, type, Props.refresh]);


   // useEffect(() => {
   //    console.log('Refresh: ', Props.refresh);
   // }, [Props.refresh]);

   return (
      <div className={style.formWrap} ref={wrapper}>
         <form onChange={handleChange} onSubmit={handleSubmit} key={updateKey} >
            {scoreSettings}
            <div className={style.btnBar}>
               <BtnSoundCheck/>
               <div className={style.largeBtn} >
                  <button className={`${style.commitBtn} ${formClass}`} type="submit"> <FontAwesomeIcon icon={faPlay} /> {btnText} </button>
               </div>
               <div className={style.smallBtn} >
                  &nbsp;
                  <CastButton></CastButton>
               </div>

            </div>
         </form>
      </div>
   );
}

export default ScoreSetupForm;