'use strict'
import React, { useEffect, useMemo, useState } from 'react';
import PlayerIcon from './player-icon/player-icon';
import logo from '../../../images/sport/generic/Logo.svg';
import logo_squash from '../../../images/sport/squash/logo.png';
import { SPORT_BASE } from '../../../javascript/services/globalVars';
import { AdjustFontSize } from '../../../javascript/utils/utils';
import style from './scoring-head-style.module.scss';

function ScoringHead(Props) {
   const [playerPosLeft, setPlayerPosLeft] = useState(() => []);
   const [playerPosRight, setPlayerPosRight] = useState(() => []);

   let logo_image = logo;
   if (SPORT_BASE.sport_object.sport_name === 'Squash') {
      logo_image = logo_squash;
   }

   const build_template = (playerObj) => {
      const template = {
         left: { name: 'Player A', },
         right: { name: 'Player B', },
         color_left: "var(--player-a-color)",
         color_right: "var(--player-b-color)",
         // color_left: { color: "var(--player-a-color)" },
         // color_right: { color: "var(--player-b-color)" },
         letter_left: 'A',
         letter_right: 'B',
      }
     
      if (typeof playerObj !== 'undefined' && playerObj.teams[0].players.length > 0) {
         const serving_player_1 = SPORT_BASE.sport_object.scoring.state.serving.side_state_1.serving_player-1;
         const serving_player_2 = SPORT_BASE.sport_object.scoring.state.serving.side_state_2.serving_player-1;
         template.color_left = typeof playerObj.teams[0].players[serving_player_1] !== 'undefined'? playerObj.teams[0].players[serving_player_1].color : "var(--player-a-color)";
         template.color_right = typeof playerObj.teams[1].players[serving_player_2] !== 'undefined'? playerObj.teams[1].players[serving_player_2].color : "var(--player-b-color)";
         template.letter_left = typeof playerObj.teams[0].players[serving_player_1] !== 'undefined'? playerObj.teams[0].players[serving_player_1].initial:'A';
         template.letter_right = typeof playerObj.teams[1].players[serving_player_2] !== 'undefined'? playerObj.teams[1].players[serving_player_2].initial:'B';
         
         if (SPORT_BASE.sport_object.scoring.serving_type === 'pickleball') {
            const game_options = SPORT_BASE.sport_object.scoring.get_setting({ setting_name: 'game_options' });
            if (game_options.value === 1 && SPORT_BASE.sport_object.scoring.state.serving.type === 'doubles') {
               template.letter_left = 'A';
               template.letter_right = 'B';
               template.color_left = "var(--player-a-color)";
               template.color_right ="var(--player-b-color)";
            }
         }

         setPlayerPosLeft([0, serving_player_1]);
         setPlayerPosRight([1, serving_player_2]);
         template.left.name = playerObj.teams[0].name;
         template.right.name = playerObj.teams[1].name;
      }

      return template;
   }

   const template = useMemo(() => {
      const { Match_Players } = SPORT_BASE.sport_object.get_player_settings();
      return build_template(Match_Players);
   }, [Props.update])

   const tally = useMemo(() => {
      return SPORT_BASE.sport_object.scoring.scoring.get_score_tally;
   }, [SPORT_BASE.sport_object.scoring.scoring.get_score_tally])

   useEffect(() => {
      setTimeout(function () {
         AdjustFontSize();
      }, 100);
   })
   useEffect(() => {
      const onResize = () => {
         setTimeout(function () {
            console.log('>>>> onResize')
            AdjustFontSize();
         }, 100);
      }
      window.addEventListener("resize", onResize);
      return () => {
         window.removeEventListener("resize", onResize);
      }
   }, []);

   const updatePlayerColor = async (playerPos, color) => {
      SPORT_BASE.sport_object.scoring.state.match.players.teams[playerPos[0]].players[playerPos[1]].color = color;
      await SPORT_BASE.saveGameState('ScoringHead');
      SPORT_BASE.updateGameStateTimestamp();
   }

   return (
      <div className={style.head}>
         <PlayerIcon letter={template.letter_left} callback={updatePlayerColor} callbackData={playerPosLeft} defaultColor={template.color_left} />
         <div className={style.nameBlock}>
            <div className={`${style.name} nameWrap`}> <span>{template.left.name}</span> </div>
            <div className={style.score}>({tally.team1})</div>
         </div>
         <div className={style.logo}><img src={logo_image} alt="logo"></img></div>
         <div className={style.nameBlock} >
            <div className={style.score}>({tally.team2})</div>
            <div className={`${style.name} nameWrap`}> <span>{template.right.name}</span> </div>
         </div>

         <PlayerIcon letter={template.letter_right} callback={updatePlayerColor} callbackData={playerPosRight} defaultColor={template.color_right} />
      </div>
   );
}

export default ScoringHead;