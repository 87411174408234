import React, { useState, useEffect, useRef } from 'react';
import { useIsVisible } from 'react-is-visible';
import MatchHistory from '../match-history/match-history';
import DBHandler from '../../javascript/index-db/db-handler';
import { build_sme_con } from '../../javascript/services/auth';
import ScoreSetupForm from './score-setup-form/score-setup-form';
import { Sleep, Loop_Timer } from '../../javascript/utils/utils';
import PushMatchLoader from '../push-match-loader/push-match-loader';
import { API_CONTROL } from '../../javascript/services/api-connection';
import { NAV_CONTROL, SPORT_BASE, GLOBAL_VARS } from '../../javascript/services/globalVars';
import style from './main-menu-style.module.scss';

const push_match_timer = new Loop_Timer('push-match-refresh', 5);

function MainMenu(Props) {
   const sport = GLOBAL_VARS.sport_name;
   const wrapper = useRef();
   const [appState, setAppState] = useState('');
   const [navUpdate, setNavUpdate] = useState(() => 0);
   const [modMenuState, setModMenuState] = useState(0);
   const [newMenuState, setNewMenuState] = useState(1);
   const [pushMatchState, setPushMatchState] = useState(() => 0);
   const [historyMenuState, setHistoryMenuState] = useState(0);
   const [isPushMatch, setIsPushMatch] = useState(() => false);
   const [showQuickMatch, setShowQuickMatch] = useState(() => true);
   const isVisible = useIsVisible(wrapper);
   
   useEffect(() => {
      updateState();
      check_push_match();
   }, [isVisible]); // >> Runs on Mount only

   const check_push_match = () => {
      const retry = () => { Sleep(5_000).then(() => { check_push_match(); }); }
      const call_api = (api_control, club_id, sport_type, device_id) => {
         return new Promise(async (resolve) => {
            try {
               const push_match_result = await api_control.SME.query.push_match_count(club_id, sport_type, device_id);
               const admin_control_result = await api_control.SME.query.admin_control(club_id, sport_type);
               if (push_match_result.match_count > 0) {
                  setIsPushMatch(true);         
               } else {
                  setIsPushMatch(false);
                  if (NAV_CONTROL.state.main_menu_tab === 4) {
                     switchTab('new');
                  }
               }
               if (admin_control_result.hide_new_match_app === 1 && push_match_result.match_count > 0) {
                  setShowQuickMatch(false);
                  if (NAV_CONTROL.state.main_menu_tab === 2 || NAV_CONTROL.state.main_menu_tab === 0) {
                     switchTab('history');
                  }
               } else {
                  setShowQuickMatch(true);
               }

               resolve(true);
            } catch (error) {
               console.error('ERROR - check_push_match - call_api: ', error);
               resolve(false);
            }
         });
      }

      const push_match_loop = async (api_control, club_id, sport_type, device_id) => {
         if (isVisible) {
            await call_api(api_control, club_id, sport_type, device_id);
            push_match_timer.start(() => {
               push_match_timer.stop();
               push_match_loop(api_control, club_id, sport_type, device_id);
            });
         }
      }

      if (isVisible) {
         try {
            const dbHandler = DBHandler();
            build_sme_con().then(async (sme_con) => {
               const api_control = API_CONTROL({ sme_con: sme_con });
               const sync = await dbHandler.load_sync();
               if (typeof sync !== 'undefined') {
                  const club = await dbHandler.load_club();
                  // console.log('club: ', club);
                  const club_id = parseInt(club.club_id);
                  const sport_type = SPORT_BASE.sport_name;
                  const device_id = parseInt(sync.device_id);
                  push_match_loop(api_control, club_id, sport_type, device_id);
               } else { retry(); }
            });
         } catch (error) {
            retry();
            push_match_timer.stop();
         }
      } else {
         push_match_timer.stop();
      }
   }

   const updateState = () => {
      setAppState(SPORT_BASE.app_state);
      if (SPORT_BASE.app_state === 'init') {
         switchTab('new');
      }
      if (modMenuState === 1 && (appState === 'init' || appState === null)) {
         switchTab('new');
      }
   }

   const switchTab = (tab) => {
      setModMenuState(0);
      setNewMenuState(0);
      setHistoryMenuState(0);
      setPushMatchState(0);
      switch (tab) {
         case 'mod':
            setModMenuState(1);
            NAV_CONTROL.state.main_menu_tab = 1;
            break;
         case 'new':
            setNewMenuState(1);
            NAV_CONTROL.state.main_menu_tab = 2;
            break;
         case 'history':
            setHistoryMenuState(1);
            NAV_CONTROL.state.main_menu_tab = 3;
            break;
         case 'pushMatch':
            setPushMatchState(1);
            NAV_CONTROL.state.main_menu_tab = 4;
            break;
         default:
            break;
      }
   }

   useEffect(() => {
      if (NAV_CONTROL.state.main_menu_tab === 1) {
         switchTab('mod');
      } else if (NAV_CONTROL.state.main_menu_tab === 2) {
         switchTab('new');
      } else if (NAV_CONTROL.state.main_menu_tab === 3) {
         switchTab('history');
      } else if (NAV_CONTROL.state.main_menu_tab === 4) {
         switchTab('pushMatch');
      }
   }, [navUpdate]); 

   useEffect(() => {
      NAV_CONTROL.appendCallback('mainMenu', () => { setNavUpdate(v => v + 1); });
      return () => {
         // >>> Runs on unLoad (Unload runs first if component is already mounted)
      };
   }, []); // >> [] -- Monitor

   const element_head = (
      <div className={style.menuNav}>
         {isPushMatch ? (<div className={`${style.navBtn} ${pushMatchState === 1 ? style.navBtnActive : ''}`} onClick={() => { switchTab('pushMatch') }}>Queued Matches</div>) : null}
         {appState !== 'init' && appState !== null ? (<div className={`${style.navBtn} ${modMenuState === 1 ? style.navBtnActive : ''}`} onClick={() => { switchTab('mod') }}>Modify Match</div>) : ('')}
         {showQuickMatch ? (<div className={`${style.navBtn} ${newMenuState === 1 ? style.navBtnActive : ''}`} onClick={() => { switchTab('new') }}>Quick Match</div>): null}
         <div className={`${style.navBtn} ${historyMenuState === 1 ? style.navBtnActive : ''}`} onClick={() => { switchTab('history') }}>Match History</div>
      </div>
   );
   const element_body = (
      <div className={style.menuWrap} ref={wrapper}>
         <div className={style.contentWrap}>
            <div className={`${style.tabContent} ${pushMatchState === 1 ? style.tabContentActive : ''}`} >
               <div className={style.tabBanner}></div>
               <PushMatchLoader/>
            </div>
            <div className={`${style.tabContent} ${modMenuState === 1 ? style.tabContentActive : ''}`} >
               <div className={style.tabBanner}></div>
               <ScoreSetupForm sport={sport} type="mod"></ScoreSetupForm>
            </div>
            <div className={`${style.tabContent} ${newMenuState === 1 ? style.tabContentActive : ''}`}>
               <div className={style.tabBanner}></div>
               <ScoreSetupForm sport={sport} refresh={navUpdate} type="new"></ScoreSetupForm>
            </div>
            <div className={`${style.tabContent} ${historyMenuState === 1 ? style.tabContentActive : ''}`}>
               <div className={style.tabBanner}></div>
               <MatchHistory update={historyMenuState}></MatchHistory>
            </div>
         </div>
      </div>
   );

   return {
      head: element_head,
      body: element_body
   }
}

export default MainMenu;